:root {
  --red-rgb: 255 105 180; /* Hot Pink */
  --blue-rgb: 135 206 250; /* Light Sky Blue */
  --green-rgb: 144 238 144; /* Light Green */
  --yellow-rgb: 255 255 102; /* Light Yellow */
  --background-rgb: 0 0 0; 
  --grid-line-rgb: 216 191 216; /* Pastel Purple Grid Lines */
}

body {
  margin: 0;
  padding: 0;
  font-family: Helvetica, sans-serif;
  background: rgb(var(--background-rgb));
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}

#container {
  width: 140rem;
  aspect-ratio: 1;
  display: grid;
  grid-template-rows: repeat(40, 1fr);
  grid-template-columns: repeat(40, 1fr);
  position: absolute;
  transform: rotate(90deg) scale(1.25);
}

#container:after,
#container:before {
  content: "";
  position: absolute;
  inset: 0px;
  pointer-events: none;
}

#container:after {
  z-index: 3;
  background: radial-gradient(circle, transparent 25%, rgb(var(--background-rgb)) 80%);
}

.tile {
  border: 1px solid rgb(var(--grid-line-rgb) / 45%);
  transition: background-color 1500ms;
}

.tile:hover {
  transition-duration: 0ms;
}

.tile:nth-child(4n):hover {
  background-color: rgb(var(--red-rgb));
}

.tile:nth-child(4n + 1):hover {
  background-color: rgb(var(--blue-rgb));
}

.tile:nth-child(4n + 2):hover {
  background-color: rgb(var(--green-rgb));
}

.tile:nth-child(4n + 3):hover {
  background-color: rgb(var(--yellow-rgb));
}

.tile:nth-child(7n):hover {
  background-color: rgb(var(--blue-rgb));
}

.tile:nth-child(7n + 3):hover {
  background-color: rgb(var(--green-rgb));
}

.tile:nth-child(7n + 5):hover {
  background-color: rgb(var(--yellow-rgb));
}

.tile:nth-child(7n + 6):hover {
  background-color: rgb(var(--red-rgb));
}

.tile:nth-child(11n + 1):hover {
  background-color: rgb(var(--red-rgb));
}

.tile:nth-child(11n + 4):hover {
  background-color: rgb(var(--blue-rgb));
}

.tile:nth-child(11n + 7):hover {
  background-color: rgb(var(--green-rgb));
}

.tile:nth-child(11n + 10):hover {
  background-color: rgb(var(--yellow-rgb));
}

.content {
  z-index: 100;
  position: relative;
}

.intro {
  font-weight: 700;
  font-family: "helvetica";
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.intro-text {
  font-size: 3em;
  color: #FFF;
  background: #38214a;
  padding-inline: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
}

button {
  width: 90px;
  height: 90px;
  font-size: 3em;
  background: #38214a;
  color: white;
  border-radius: 10px;
  border: 2px solid white;
  font-weight: 100;
  cursor: pointer;
  transition: 0.3s;
}

button:hover {
  background: #e4ddeb;
  color: #38214a;
}

.links {
  margin-top: 130px;
  display: flex;
  gap: 20px;
  justify-content: center;
}